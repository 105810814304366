import React from "react";
import Typewriter from "typewriter-effect";
import Fade from 'react-reveal/Fade';

import pow from "./graphics/pow.svg";
import Footer from "./Footer";

function Contact() {
    return (
        <div id={"contact"} name={"contact"}>
            <div className={"contact-content"}>
                <Typewriter
                    onInit={(typewriter) => {
                        typewriter.typeString('Contact()')
                            .pauseFor(2000)
                            .deleteAll()
                            .deleteAll()
                            .start()
                    }}
                    options={{loop: true, cursor:'_'}}
                />
                <Fade bottom cascade>
                    <div className={"contact-text"}>
                        <p>Feel free to reach out! I'm always happy to chat about new opportunities. </p>
                        <a href={"mailto:zhiming.leong@duke.edu"} ><img className={"pow"} src={pow} /></a>
                        {window.innerWidth <= 768 && <Footer/>}
                    </div>
                </Fade>
            </div>
        </div>
    )
}

export default Contact;