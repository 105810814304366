import React from "react";

import pipe from "./graphics/pipe.png"

function Footer() {
    return (
        <div id={"footer"}>
            <div className={"social-icons"}>
                <a href={"https://www.linkedin.com/in/jameszleong/"} target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in"></i></a>
                <a style={{animationDelay: '0.2s'}} href={"https://github.com/JamesLeong98"} target="_blank" rel="noopener noreferrer"><i className="fab fa-github"></i></a>
                <a style={{animationDelay: '0.4s'}} href={"https://www.facebook.com/jameszleong/"} target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f" style={{marginLeft: "3px"}}></i></a>
                <a style={{animationDelay: '0.6s'}} href={"mailto:zhiming.leong@duke.edu"}><i className="fas fa-envelope"></i></a>
            </div>
            <img className={"pipe"} src={pipe}/>
        </div>
    )
}

export default Footer;