import React from "react";
import Typewriter from "typewriter-effect";
import Zoom from 'react-reveal/Zoom';

import star from "./graphics/star.png"

function Experience() {

    return (
        <div id={"experience"} name={"experience"}>
            <div className={"experience-content"}>
                <Typewriter
                    onInit={(typewriter) => {
                        typewriter.typeString('Exp()')
                            .pauseFor(2000)
                            .deleteAll()
                            .deleteAll()
                            .start()
                    }}
                    options={{loop: true, cursor:'_'}}
                />
                <Zoom duration={700}>
                    <div className={"experience-container"}>
                        <h3><img src={star} /> Stripe</h3>
                        <p>Software Engineer Intern | <span className={"location"}>Seattle, WA</span></p>
                        <p style={{marginBottom: "30px"}}>May 2022 - Jul 2022</p>
                        <h3><img src={star} /> TikTok (Bytedance)</h3>
                        <p>Software Engineer Intern | <span className={"location"}>Mountain View, CA</span></p>
                        <p style={{marginBottom: "30px"}}>May 2021 - Aug 2021</p>
                        <h3><img src={star} /> Duke Database Group</h3>
                        <p>Student Software Developer | <span className={"location"}>Durham, NC</span></p>
                        <p style={{marginBottom: "30px"}}>Jan 2021 - Present</p>
                        <h3><img src={star} /> Stealth Startup</h3>
                        <p>Software Engineer Intern | <span className={"location"}>Singapore</span></p>
                        <p style={{marginBottom: "30px"}}>May 2020 - Aug 2020</p>
                        <a href={"https://www.linkedin.com/in/jameszleong/"} target="_blank" rel="noopener noreferrer"><i class="fab fa-linkedin"></i></a>
                    </div>
                </Zoom>
            </div>
        </div>
    )
}

export default Experience;