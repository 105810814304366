import React from "react";
import Typewriter from "typewriter-effect";
import Bounce from 'react-reveal/Bounce';

function Projects() {
    return (
        <div id={"projects"} name={"projects"}>
            <div className={"projects-content"}>
                <Typewriter
                    onInit={(typewriter) => {
                        typewriter.typeString('Projects()')
                            .pauseFor(2000)
                            .deleteAll()
                            .deleteAll()
                            .start()
                    }}
                    options={{loop: true, cursor:'_'}}
                />
                <Bounce cascade>
                    <div className={"boxes"}>
                        <div className={"box-1"}>
                            <div className={"box-text"}>
                                <h3>Duke Mobile Planner</h3>
                                <p>iOS app for Duke students to plan their academic schedule, integrated with Duke curriculum APIs  <a href={"https://github.com/JamesLeong98/duke-personal-assistant"} target="_blank" rel="noopener noreferrer"><i className="fas fa-link"></i></a></p>
                                <div className={"chip"}>Swift</div>
                                <div className={"chip"}>Xcode</div>
                            </div>
                        </div>
                        <div className={"box-2"}>
                            <div className={"box-text"}>
                                <h3>Marketplace</h3>
                                <p>Amazon clone web app that allows users to buy, sell and search for items on an online marketplace.  <a href={"https://github.com/JamesLeong98/miniamazon-cs316"} target="_blank" rel="noopener noreferrer"><i className="fas fa-link"></i></a></p>
                                <div className={"chip"}>React</div>
                                <div className={"chip"}>Node</div>
                                <div className={"chip"}>MongoDB</div>
                                <div className={"chip"}>Express</div>
                            </div>
                        </div>
                        <div className={"box-3"}>
                            <div className={"box-text"}>
                                <h3>Covid Tracker</h3>
                                <p>iOS app that displays covid info across the US  <a href={"https://github.com/JamesLeong98/covid-app"} target="_blank" rel="noopener noreferrer"><i className="fas fa-link"></i></a></p>
                                <div className={"chip"}>Swift</div>
                                <div className={"chip"}>Xcode</div>
                            </div>
                        </div>
                        <div className={"box-4"}>
                            <div className={"box-text"}>
                                <h3>Spotify Mood Playlist Maker</h3>
                                <p>Web app that generates a playlist based on selected mood indicators  <a href={"https://github.com/JamesLeong98/Vibe-PennAppsXXI"} target="_blank" rel="noopener noreferrer"><i className="fas fa-link"></i></a></p>
                                <div className={"chip"}>React</div>
                                <div className={"chip"}>Flask</div>
                                <div className={"chip"}>WolframAI</div>
                                <div className={"chip"}>BeautifulSoup</div>
                            </div>
                        </div>
                    </div>
                </Bounce>
            </div>
        </div>
    )
}

export default Projects;