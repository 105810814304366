import React from "react";
import { Link, animateScroll as scroll } from 'react-scroll'

import mushroom from "./graphics/red-mushroom.png"

function Navbar() {

    //hides navbar on scroll down
    let prevScrollPos = window.pageYOffset;
    window.onscroll = function() {
        let currentScrollPos = window.pageYOffset;
        if (prevScrollPos > currentScrollPos) {
            document.getElementById("navbar").style.top = "0";
        } else {
            document.getElementById("navbar").style.top = "-60px";
        }
        prevScrollPos = currentScrollPos;
    }

    const scrollToTop = () => {
        scroll.scrollToTop();
    }

    return (
        <div id={"navbar"}>
            <img className={"home-button"} src={mushroom} onClick={scrollToTop}/>
            <div className={"nav-contents"}>
                <ul>
                    <li><Link activeClass="active" className="about-nav nav-button" to="about" spy={true} smooth={true} duration={500}>About</Link></li>
                    <li><Link activeClass="active" className="experience-nav nav-button" to="experience" spy={true} smooth={true} duration={500}>Experience</Link></li>
                    <li><Link activeClass="active" className="projects-nav nav-button" to="projects" spy={true} smooth={true} duration={500}>Projects</Link></li>
                    <li><Link activeClass="active" className="contact-nav nav-button" to="contact" spy={true} smooth={true} duration={500}>Contact</Link></li>
                    <li><a className="nav-button" href="resume.pdf">Resume</a></li>
                    {/*<li><Link activeClass="active" className="gallery-nav" to="gallery" spy={true} smooth={true} duration={500} >Gallery</Link></li>*/}
                </ul>
            </div>
        </div>
    )
}

export default Navbar;