import React from "react";
import Typewriter from "typewriter-effect";
import Fade from 'react-reveal/Fade';

import coin from "./graphics/coin.png"
import james from "./graphics/james.png";

function About() {
    return (
        <div id={"about"} name={"about"}>
            <div className={"about-page"}>
                <Typewriter
                    onInit={(typewriter) => {
                        typewriter.typeString('About()')
                            .pauseFor(2000)
                            .deleteAll()
                            .deleteAll()
                            .start()
                    }}
                    options={{loop: true, cursor:'_'}}
                />
                <Fade bottom cascade>
                    <div className={"about-content"}>
                        <div className={"about-text"}>
                            <p>Hi! My name is <span className={"highlight-green"}>James</span>, and I'm a senior at <span className={"highlight-green"}>Duke University</span>.</p>
                            <p style={{marginTop: "30px"}}>{`self().facts = {`}</p>
                            <div className={"about-facts"}>
                                <p><img src={coin}/>{` major = computer science 💻`}</p>
                                <p><img src={coin}/>{` current_location = Durham, NC 🇺🇸󠁳󠁮󠁣󠁿`}</p>
                                <p><img src={coin}/>{` hometown = Singapore 🇸🇬`}</p>
                                <p><img src={coin}/>{` interests = [traveling ✈️, karaoke 🎤, K-dramas 🇰🇷]`}</p>
                            </div>
                            <p>{`}`}</p>
                        </div>
                        <div className={"photo-container"}>
                            <img className={"about-photo"} src={james} alt={"self-portrait"}/>
                        </div>
                    </div>
                </Fade>
            </div>
        </div>
    )
}

export default About;