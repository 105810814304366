import React from "react";
import Typewriter from 'typewriter-effect';
import Fade from 'react-reveal/Fade';

function Landing() {
    return (
        <Fade big>
            <div id={"landing"}>
                <div className={"typewriter"}>
                    <Typewriter
                        onInit={(typewriter) => {
                            typewriter.typeString('Hello World')
                                .pauseFor(1000)
                                .deleteAll()
                                .typeString('Welcome to my site!')
                                .pauseFor(1000)
                                .deleteAll()
                                .start()
                        }}
                        options={{loop: true, cursor:'_'}}
                    />
                </div>
            </div>
        </Fade>
    )
}

export default Landing;