import './App.css';
import Landing from "./Landing";
import Navbar from "./Navbar";
import About from "./About";
import Footer from "./Footer";
import Experience from "./Experience";
import Projects from "./Projects";
import Contact from "./Contact";

function App() {
  return (
    <div className="App">
        {window.innerWidth > 450 && <Navbar/>}
        <Landing/>
        <About/>
        <Experience/>
        <Projects/>
        <Contact/>
        {window.innerWidth > 768 && <Footer/>}
    </div>
  );
}

export default App;
